import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrl: './auth-container.component.scss'
})
export class AuthContainerComponent {
  mode: string = 'registration';

  constructor(public activeModal: NgbActiveModal) {}

  toggleMode(mode: string) {
    this.mode = mode;
  }

}
