import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrl: './success-modal.component.scss'
})
export class SuccessModalComponent {
  currentUrl: string = this.router.url;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
  ) { }

  backToBrand() {
    this.router.navigate([this.currentUrl]);
    this.activeModal.close();
  }

}
