<div class="container bg-white py-5">
  <div class="row">
    <div class="col-12 d-block d-md-none">
      <img cCardImg="top" src="../../../assets/images/f1.png" width="112" class="mx-auto mt-3 mb-2 d-block d-md-none" role="button" alt="Franchise One Logo" [routerLink]="['/']">
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-12 form-container d-flex flex-column justify-content-between">
      <div class="flex-fill"></div>
      <img
        src="assets/images/congratulations.svg"
        alt="Congratulations"
        class="img-fluid mb-3 mx-auto"
        style="max-width: 300px"
      />
      <h2 class="text-center fw-bold">Success!</h2>
      <h6 cCardTitle class="text-center mb-5 fw-normal">
        Congratulations. Your account has been created successfully.
      </h6>
      <div class="card mx-auto border-0 mt-5">
        <div class="card-body">
          <div class="d-grid">
            <button class="btn btn-primary fw-medium" type="button" (click)="backToBrand()">Back to the brand</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
