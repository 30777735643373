import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { ToastService } from '../../../core/services/toast.service';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateInformationFormComponent } from '../update-information-form/update-information-form.component';

@Component({
  selector: 'app-verify-otp-form',
  templateUrl: './verify-otp-form.component.html',
  styleUrl: './verify-otp-form.component.scss'
})
export class VerifyOtpFormComponent {
  @ViewChildren('otp0, otp1, otp2, otp3, otp4, otp5') otpInputs!: QueryList<any>;
  verifyOtpForm!: FormGroup;
  otpArray: string[] = new Array(6).fill('');
  isSubmitting: boolean = false;
  @Input() accountForm!: any;
  currentUrl: string = this.router.url;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router,
    private modal: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    this.verifyOtpForm = this.fb.group({
      phoneNumber: [this.accountForm.phoneNumber, [Validators.required]],
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
    this.sendCode(this.accountForm.phoneNumber);
  }


  onOtpInput(event: any, index: number) {
    const input = event.target;
    const value = input.value;

    if (/[^0-9]/.test(value)) {
      input.value = '';
      return;
    }

    this.otpArray[index] = value;

    if (value && index < 5) {
      const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
      nextInput.focus();
    }

    const otp = this.otpArray.join('');
    if (otp.length === 6 && !this.isSubmitting) {
      this.isSubmitting = true;
      this.verifyOtpForm.patchValue({ otp });
      this.submitForm();
    }
  }

  onOtpKeyDown(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;

    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.preventDefault();
      this.otpArray[index] = '';
      input.value = '';

      if (index > 0 && event.key === 'Backspace') {
        const prevInput = this.otpInputs.toArray()[index - 1].nativeElement;
        prevInput.focus();
      }
    } else if (event.key === 'ArrowLeft' && index > 0) {
      event.preventDefault();
      const prevInput = this.otpInputs.toArray()[index - 1].nativeElement;
      prevInput.focus();
    } else if (event.key === 'ArrowRight' && index < 5) {
      event.preventDefault();
      const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
      nextInput.focus();
    } else if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
      input.value = event.key;
      this.otpArray[index] = event.key;

      if (index < 5) {
        const nextInput = this.otpInputs.toArray()[index + 1].nativeElement;
        nextInput.focus();
      }

      const otp = this.otpArray.join('');
      if (otp.length === 6 && !this.isSubmitting) {
        this.isSubmitting = true;
        this.verifyOtpForm.patchValue({ otp });
        this.submitForm();
      }
    }
  }

  submitForm() {
    if (this.verifyOtpForm.valid) {
      this.auth.verifyOtp(this.accountForm.phoneNumber, this.verifyOtpForm.value.otp)
        .subscribe({
          next: (response: Response) => {
            this.toast.show({ textOrHtml: 'OTP verified successfully', classname: 'bg-success text-white', delay: 1500 });
            this.login();
            this.isSubmitting = false;
          },
          error: (error) => {
            this.toast.show({ textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500 });
            this.isSubmitting = false;
          }
        });
    } else {
      this.isSubmitting = false;
    }
  }

  sendCode(phone: string) {
    this.auth.sendOtp(phone).subscribe({
      next: (response: Response) => {
        this.toast.show({textOrHtml: 'OTP sent successfully!', classname: 'bg-success text-white', delay: 1500});
      },
      error: (error) => {
        console.error('Error sending OTP', error);
        this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500});
      }
    });
  }

  login() {
    this.auth.login(this.accountForm.phoneNumber, this.accountForm.password).subscribe({
      next: (response: Response) => {
        this.toast.show({textOrHtml: 'Login successful', classname: 'bg-success text-white', delay: 1500});
        this.openBasicInfoModal();
        this.activeModal.close();
      },
      error: (error) => {
        console.error('Error logging in', error);
        this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500});
      }
    });
  }

  openBasicInfoModal() {
    const modalRef = this.modal.open(UpdateInformationFormComponent, { centered: true, keyboard: false, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.accountDetail = this.accountForm;
  }

}
