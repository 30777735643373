<div class="form-container">
  <div class="card border-0">
    <h4 class="text-center fw-bold text-primary mt-3">Basic Information</h4>
    <ol class="numbered-list list-inline">
      <li class="list-inline-item" data-step="1"><span>Create Account</span></li>
      <li class="list-inline-item" data-step="2"><span>Account Verification</span></li>
      <li class="list-inline-item active" data-step="3"><span>Update Information</span></li>
    </ol>
    <h6 class="card-title text-center mb-5 fw-normal text-secondary">We require your basic business information to gain a deeper understanding of your enterprise</h6>
    <div class="card flex-fill border-0">
      <div class="card-body mt-3">
        <form [formGroup]="basicForm" (submit)="onSubmit($event)" class="h-100 d-flex flex-column justify-content-between">
          <div class="row flex-grow-1">
            <div class="col-12" *ngIf="accountDetail?.activeRole === 'Franchisee'">
              <div class="mb-3">
                <label class="fw-bold" for="budgetTier">Budget</label>
                <select id="budgetTier" class="form-select" formControlName="budgetTier">
                  <option value="" selected disabled>- select budget -</option>
                  <option value="1">Under 10 million</option>
                  <option value="2">10 million - 100 million</option>
                  <option value="3">100 million - 500 million</option>
                  <option value="4">500 million - 1 billion</option>
                  <option value="5">1 billion - 5 billion</option>
                  <option value="6">Over 5 billion</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="fw-bold" for="occupation">Occupation</label>
                <select id="occupation" class="form-select" formControlName="occupation">
                  <option value="" selected disabled>- select occupation -</option>
                  <option value="Entrepreneur">Entrepreneur</option>
                  <option value="Home Maker">Home Maker</option>
                  <option value="Professional">Professional</option>
                  <option value="Retired">Retired</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="fw-bold" for="hadFranchise">Ever had a franchise?</label>
                <select id="hadFranchise" class="form-select" formControlName="hadFranchise">
                  <option [value]="true">Yes</option>
                  <option [value]="false">No</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="fw-bold" for="haveTime">Have time to manage operations?</label>
                <select id="haveTime" class="form-select" formControlName="haveTime">
                  <option [value]="true">Yes</option>
                  <option [value]="false">No</option>
                </select>
              </div>
            </div>
          </div>
          <div class="d-grid mt-3">
            <input role="button" type="submit" class="btn btn-primary col-md-6 mx-auto" value="Register My Account" />
          </div>
          <div class="d-grid text-center mt-3">
            <p>
              <a
                (click)="skipStep()" role="button"
                class="text-primary text-decoration-none fw-light"
                ><small>Skip this step</small></a
              >
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>