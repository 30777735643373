import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { ToastService } from '../../../core/services/toast.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'app-update-information-form',
  templateUrl: './update-information-form.component.html',
  styleUrl: './update-information-form.component.scss'
})
export class UpdateInformationFormComponent {
  basicForm!: FormGroup;
  @Input() accountDetail: any;
  currentUrl: string = this.router.url;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
  ) { }

  ngOnInit() {
    console.log(this.accountDetail);
    this.basicForm = this.fb.group({
      budgetTier: ['', Validators.required],
      occupation: ['', Validators.required],
      hadFranchise: [false, Validators.required],
      haveTime: [false, Validators.required],
    });

    this.api.getProfile().subscribe((data) => {
      this.basicForm.patchValue(data);
    });
  }

  onSubmit(e: any) {
    e.preventDefault();
    if (this.basicForm.valid) {
      this.api.updateProfile(this.basicForm.value).subscribe({
        next: (data) => {
          this.toast.show({textOrHtml: data.message, classname: 'bg-success text-white', delay: 1500});
          this.skipStep();
        },
        error: (error) => {
          this.toast.show({textOrHtml: error.message, classname: 'bg-danger text-white', delay: 1500});
        },
      });
    }
  }

  skipStep() {
    this.activeModal.close();
    const modalRef = this.modal.open(SuccessModalComponent, { centered: true, keyboard: false, backdrop: 'static', size: 'lg' });
  }

}
