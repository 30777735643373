<div class="modal-body">
  <app-login-form *ngIf="mode === 'login'"></app-login-form>
  <app-registration-form *ngIf="mode === 'registration'"></app-registration-form>
  <div class="text-center">
    <p *ngIf="mode === 'login'">Don't have an account? <a class="text-primary text-decoration-none" type="button" (click)="toggleMode('registration')"><strong>Register</strong></a></p>
    <p *ngIf="mode === 'registration'">Have an account already? <a class="text-primary text-decoration-none" type="button" (click)="toggleMode('login')"><strong>Login</strong></a></p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>