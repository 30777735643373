<!-- Form Container -->
<div class="form-container">
  <div class="card border-0">
    <h4 class="text-center text-primary fw-bold mt-3">Login</h4>
    <div class="card-body">
      <form [formGroup]="loginForm" (submit)="onSubmit()">
        <div class="mb-3">
          <label for="phoneNumber"><strong>Phone Number</strong></label>
          <input id="phoneNumber" type="tel" formControlName="phoneNumber" #phoneNumber name="phoneNumber" class="form-control" required>
          <div *ngIf="loginForm.controls['phoneNumber'].errors?.['required'] && loginForm.controls['phoneNumber'].touched">
            <small class="text-danger">Your phone number is required</small>
          </div>
        </div>
        <div class="mb-3">
          <label for="password"><strong>Password</strong></label>
          <input id="password" type="password" formControlName="password" name="password" class="form-control" required>
          <div *ngIf="loginForm.controls['password'].errors?.['required'] && loginForm.controls['password'].touched">
            <small class="text-danger">Your password is required</small>
          </div>
        </div>
        <div class="text-end">
          <a routerLink="/forgot-password" class="text-primary text-decoration-none"><strong>Forgot password?</strong></a>
        </div>
        <br>
        <div class="d-grid">
          <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">Login</button>
        </div>
      </form>
    </div>
  </div>
  <a class="position-absolute bottom-0 end-0 text-white" routerLink="/login-admin">.</a>
</div>
