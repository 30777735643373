<div class="form-container">
  <div class="card border-0">
    <h4 class="text-center text-primary fw-bold mt-3">Register</h4>
    <ol class="numbered-list list-inline">
      <!-- <li class="list-inline-item active" data-step="1"><span>Choose a Role</span></li> -->
      <li class="list-inline-item active" data-step="1"><span>Create Account</span></li>
      <li class="list-inline-item" data-step="2"><span>Account Verification</span></li>
      <li class="list-inline-item" data-step="3"><span>Update Information</span></li>
    </ol>
    <div class="row pt-3">
      <div class="col-12 form-container d-flex flex-column justify-content-between">
        <h3 class="text-center fw-bold text-primary">Create your Account</h3>
        <h6 class="card-title text-center fw-normal text-secondary">Please create your account before continuing</h6>
        <div class="card flex-fill border-0">
          <div class="card-body mt-3">
            <form [formGroup]="accountForm" (submit)="onSubmit($event)" class="h-100 d-flex flex-column justify-content-between">
              <div class="row flex-grow-1">
                <div class="col-12 mx-auto">
                  <div class="mb-3">
                    <label for="name">Full Name</label>
                    <input id="name" formControlName="name" type="text" class="form-control" placeholder="your full name" [ngClass]="{'is-invalid': isControlInvalid('name')}" />
                    <div *ngIf="isControlInvalid('name', 'required')" class="invalid-feedback">
                      Full name is required
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="phoneNumber">Phone Number</label>
                    <input id="phoneNumber" formControlName="phoneNumber" #phoneNumber type="tel" class="form-control" placeholder="your WhatsApp number" [ngClass]="{'is-invalid': isControlInvalid('phoneNumber')}" />
                    <div *ngIf="isControlInvalid('phoneNumber', 'required')" class="invalid-feedback" [ngStyle]="{'display': isControlInvalid('phoneNumber', 'required') ? 'block' : 'none'}">
                      Your WhatsApp phone number is required
                    </div>
                  </div>
  
                  <div class="mb-3">
                    <label for="email">Email</label>
                    <input id="email" formControlName="email" #email type="email" class="form-control" placeholder="your email" [ngClass]="{'is-invalid': isControlInvalid('email')}" />
                    <div *ngIf="isControlInvalid('email', 'required')" class="invalid-feedback">
                      Your email is required
                    </div>
                    <div *ngIf="isControlInvalid('email', 'email')" class="invalid-feedback">
                      Incorrect email format
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="yourPassword">Password</label>
                    <div class="input-group">
                      <input
                        id="yourPassword"
                        formControlName="password"
                        [type]="showPassword ? 'text' : 'password'"
                        class="form-control"
                        placeholder="8 characters alphanumeric"
                        [ngClass]="{'is-invalid': isControlInvalid('password')}"
                      />
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        (click)="togglePasswordVisibility()"
                      >
                      <i [class]="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                      </button>
                      <div *ngIf="isControlInvalid('password', 'required')" class="invalid-feedback">
                        Password is required
                      </div>
                      <div *ngIf="isControlInvalid('password', 'minlength')" class="invalid-feedback">
                        Your password must have a minimum of 8 characters
                      </div>
                    </div>
                  </div>
                  <div class="mb-3" *ngIf="activeRole === 'Franchisee'">
                    <label for="referrer">Referral Code</label>
                    <input id="referrer" formControlName="referrer" type="text" class="form-control" 
                      [readonly]="qrRef" placeholder="referral code" 
                      [ngClass]="{'is-invalid': isControlInvalid('referrer'), 'bg-secondary-subtle': qrRef}" 
                      [ngStyle]="{'cursor': qrRef ? 'not-allowed' : 'default'}">
                  </div>
                </div>
              </div>
              <div class="row flex-grow-1 mt-3">
                <div class="col-12 col-md-6 mx-auto">
                  <button type="submit" class="btn btn-primary col-md-6 mx-auto w-100" [disabled]="isProcessing">
                    <i *ngIf="isProcessing" class="spinner-border spinner-border-sm"></i>
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>